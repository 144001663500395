import { render, staticRenderFns } from "./SingleClient.vue?vue&type=template&id=915d8318&scoped=true&"
import script from "./SingleClient.vue?vue&type=script&lang=js&"
export * from "./SingleClient.vue?vue&type=script&lang=js&"
import style0 from "./SingleClient.vue?vue&type=style&index=0&id=915d8318&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "915d8318",
  null
  
)

export default component.exports